 @font-face {
   font-family: "SFProdisplay";
   src: local("SFProdisplay"),
     url("../styles/fonts/sf_pro_display.ttf") format("opentype");
 }

 @font-face {
   font-family: "Roboto";
   src: local("Roboto"),
     url("../styles/fonts/Roboto.ttf") format("opentype");
 }

 .header-box {
   display: flex;
   margin-bottom: 30px;
 }

 .request-heading {
   width: 121px;
   height: 14px;
   font-family: 'SFProdisplay';
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 14px;
   color: #000000;
   display: inline;
 }

 .icon-style {
   color: #005724;
   width: 30px;
   height: 15px;
   margin-left: 10px;
   cursor: pointer;
 }









 /* Code By Webdevtrick ( https://webdevtrick.com ) */
 .clearfix:after {
   clear: both;
   content: "";
   display: block;
   height: 0;
   margin-top: 0px;
 }

 .container {
   font-family: "Lato", sans-serif;
   width: 100%;
 }

 .wrapper {
   display: table-cell;
   height: 400px;
   vertical-align: middle;
 }

 .nav {
   margin-top: 40px;
 }

 .pull-right {
   float: right;
 }

 a,
 a:active {
   color: #212121;
   text-decoration: none;
 }

 a:hover {
   color: #999;
 }


 .stepper {
   width: 100%;
   margin-inline: 32px;
   margin-right: 16px;
   display: flex;
   padding: 0;
   list-style: none;
   position: relative;
 }

 .stepper::before {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   content: "";
   width: calc(100% - 20px);
   background: transparent;
 }

 .step {
   flex: 100%;
   padding: 15px;
   text-align: center;
   color: #a9a9a9;
   background: #f9ead4;
   margin: 0 0 0 -15px;
   font-weight: bold;
   font-size: 16px;
   -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
 }

 .current {
   color: #ffffff;
   background: #ff9a01;
 }

 .complete {
   color: #005724;
   background: #f9ead4;
 }

 .step:first-child {
   padding: 15px;
   border-radius: 7px 0 0 7px;
   -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
 }

 .step:last-child {
   border-radius: 0 7px 7px 0;
   -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
 }