.modal-box {
    width: 663px;
    height: 347px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.main-card {
    width: 811px;
    height: 400px auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.icon-style {
    left: 80.23%;
    right: 18.91%;
    top: 28.68%;
    bottom: 70.34%;
    background: #005724;
}

.main-title {
    width: 185px;
    height: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */
    text-align: center;
    color: #000000;
}

.sub-title {
    position: absolute;
    width: 414px;
    height: 50px;
    left: 521px;
    top: 542px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    /* or 179% */
    text-align: center;
    color: #000000;
}