@font-face {
  font-family: "fontsfree";
  src: local("fontsfree"),
    url(../styles/fonts/FontsFree-Net-SFProDisplay-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

.maintxt {
  height: 16px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #005724;
}

.mainsubtxt {
  height: 16px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin: 12px 0;
}

.boldtxt {
  font-weight: bold;
}
