 table {
     border-collapse: separate;
     border-spacing: 0 5px;
 }

 .table-title > th, .table-title > td{
   
        height: 50px;
        text-align: start;
    
 }



 .table-title th:last-child {
     padding-right: 20px;
 }

 .table-title td:first-child {
     border-top-left-radius: 7px;
     border-bottom-left-radius: 7px;
     margin-left: 10px;
 }

 .table-title td:last-child {
     border-top-right-radius: 7px;
     border-bottom-right-radius: 7px;
     padding-right: 20px;
 }

 .border-top-bottom{
    border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
 }

