:root {
    --dashed-border-height: 0px;
    --dashed-border-width: 0px;
}

.rootBox {
    position: relative;
}

.rootBox::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    height: calc(var(--dashed-border-height) - 84%);
    border-left: 2px dashed #aeaeae;
    background: transparent;
}


.centerButton {
    position: relative;
    border-radius: 7px;
    font-size: 14px;
    font-weight: bold;
    padding-block: 8px;
    color: #FFFFFF;
    background-color: #FF9A01;
}

.centerButton:hover {
    background-color: #FF9A01;
}


.centerButton:disable {
    background-color: #C5C5C5;
}

.centerButton::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    width: var(--dashed-border-width);
    border-top: 2px dashed #aeaeae;
}

.centerButton::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    width: var(--dashed-border-width);
    border-top: 2px dashed #aeaeae;
}

.outer {
    width: 175px;
    height: 5px;
    border-radius: 30px;
    background-color: #FF9A01;
}

.pending {
    width: 175px;
    height: 5px;
    border-radius: 30px;
    background-color: #FF9A01;
}

.stopped {
    width: 175px;
    height: 5px;
    border-radius: 30px;
    background-color: red;
}

.completed {
    width: 175px;
    height: 5px;
    border-radius: 30px;
    background-color: #005724;
}

.inner {
    height: 5px;
    border-radius: 10px;
    transition: 0.4s linear;
    transition-property: width;
}

.move .inner {
    background-color: #005724;
    animation: progress 3s infinite;
}

@keyframes progress {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}