@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../styles/fonts/Roboto.ttf) format("opentype");
}

/* main dialog box */
.main_dialogbox {
  width: 552px;
  height: 290px;
  left: 364px;
  top: 215px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

/* main heading clear Reconciliation of dialog box */
.clear_heading {
  width: 146px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  color: #000000;
  margin-top: 15px;
}

/* middle content of dialog box */
.main_content {
  width: 418px;
  height: 60px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Ibtn {
  margin-top: -15px;
}
