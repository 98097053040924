@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../styles/fonts/Roboto.ttf) format("opentype");
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.textfieldcommon {
  background-color: white;
}

/* All correct css */
.titlewrapper {
  display: flex;
}

.leftorangebox {
  width: 4px;
  height: 24px;
  display: "inline";
  background: #ff9a01;
  border-radius: 5px;
}

.uploadtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-left: 15px;
  height: 19px;
  margin-bottom: 0px;
}

.dropzone {
  background: #f3f3f3;
  border: 2px dashed #a0a0a0;
  border-radius: 7.56957px;
  padding: 30px;
  margin-left: 30px;
  height: 409px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.uploadbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sub-titles {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.sub-sub-title {
  width: 200px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-bottom: 30px;
  text-align: center;
}

.upload-title {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 11px;
  text-align: center;
  color: #000000;
}

.icon-title {
  width: 50px;
  height: 60px;
}

.imagewrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 50px;
}

.img1 {
  width: "158px";
  height: "178px";
}

.text-front {
  display: "flex";
  justify-content: "space-around";
}

.side-text {
  /* width: 150px; */
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #005724;
}

.label-text {
  width: 82px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.text-danger {
  color: #ff0000;
}

.btn-browse {
  width: 123px;
  height: 35px;
  background: #005724;
  border-radius: 5px;
}

.btn-next {
  width: 123px;
  height: 35px;
  background: #005724;
  border-radius: 5px;
}

.btn-back {
  width: 123px;
  height: 35px;
  background: #ffffff;
  color: #005724;
  border: 1px solid #ff9a01;
  box-sizing: "border-box";
  border-radius: 5px;
}

.btn-browse:hover {
  background: #005724;
}

.btn-next:hover {
  background: #005724;
}

.btn-back:hover {
  background: #ffffff;
  color: #005724;
  border: 1px solid #ff9a01;
}

.right-icon {
  display: flex;
  margin-left: auto;
  width: 60px;
  height: 30px;
  cursor: pointer;
}

.file-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.files {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.file-size {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
