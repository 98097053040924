@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../styles/fonts/Roboto.ttf) format("opentype");
}

.main_heading {
  width: 132px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.right_side_display {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 20px;
  padding-block: 30px;
  background: #f3f3f3;
  border-radius: 7px;
}

.left_side_display {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px;
}

.addparty {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #005724;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  text-decoration: underline;
}

/* button box */
/* .below-btn-section {
    gap: 20px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
} */

/* legder and open item button */
.toggle_btn {
  box-sizing: border-box;
  width: 300px;
  height: 33px;
  border: 0.5px solid #bbbbbb;
  border-radius: 5px;
}

/* name and icon div-right side */
.upper_head {
  /* width: 75%; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  /* ;
    margin-left: 60px;
    margin-top: -30px; */
  position: relative;
}

/* setting- heading */
.setting_head {
  display: flex;
  width: 60px;
  height: 19px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

/* 
.display_msg_box {
    display: flex;
    position:static;
} */

/* info i icon */
.infoicondata {
  display: flex;
  width: 200px;
  height: 80px;
  background: linear-gradient(
      0deg,
      rgba(255, 154, 1, 0.24),
      rgba(255, 154, 1, 0.24)
    ),
    #ffffff;
  border-radius: 5px;
  padding: 10px;
  /* top: 0;right: 0;
        position: absolute; */
}

/* Information message box on icon click */
.InfoMessage {
  width: 170px;
  height: 65px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 156.69%;
  color: #005724;
}

/* labels style */
.sub-head-party {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

/* action- Menu Items of bar button */
.action_heading {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #005724;
}

.card_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

/* names- Sub Menu Items of bar button */
.sub_menu_headings {
  display: flex;
  gap: 10px;
  padding-right: 50px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #000000;
}

/* icons- Sub Menu Items of bar button */
.sub_menu_icons {
  height: 30px;
  width: 20px;
  border-radius: 0px;
  margin-left: 10px;
  color: #005724;
}

.arrow-back-icon {
  width: 16px;
  height: 16px;
  color: #005724;
  cursor: pointer;
}

/* .clear-icon {
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    cursor: pointer;
    color: #005724;
} */

.bar-icon {
  /* width: 24px;
    height: 24px; */
  background: #ececec;
  border-radius: 5px;
  cursor: pointer;
}

.close-icon {
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  position: absolute;
}

.Ibtn {
  margin-top: 40px;
}

.setting_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

/* statement request label */
.reconciliation_heading {
  width: 188px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 14px;
  color: #000000;
  margin-left: 15px;
  margin-top: 10px;
}

/* search box */
.recosearchbox {
  width: 300px;
  height: 40px;
  border-radius: 34px;
  margin-right: 15px;
  background: #f3f3f3;
}

/* search icon */
.iconwrapper {
  width: 50px;
  height: 40px;
  color: #005724;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* full search box */
.search_addbtn_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

/* upper box-label,search and add new button */
.statementtable-box {
  /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px; */
  display: "flex";
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: -20px;
}

.outer_gray_box {
  width: 1009px auto;
  height: 548px auto;
  background: #f3f3f3;
  border-radius: 7.56957px;
  margin-top: 20px;
}

/* table */
.statementtable-container {
  width: 977px auto;
  height: 441px auto;
  margin: 16px;
  border: 0.5px solid #bbbbbb;
  border-radius: 7.56957px;
}

.viewlink {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  /* line-height: 11px; */
  /* text-align: center; */
  text-decoration: underline;
  color: #005724;
  cursor: "pointer";

  /* sx={{fontWeight: "700", textDecoration: "underline", cursor:"pointer", color: "#005724" }} */
}
.viewlink Link:hover {
  color: #005724;
}
