.dropbtn {
  display: flex;
  /* flex: "1 1 0px"; */
  color: lightslategray;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
}

.dropdown {
  /* position: absolute; */
  display: inline-block;
  z-index: 999;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 90px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.dropdown-content a {
  color: lightslategray;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}

/* upper box-search new and bar button */
.partymastertable-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

/* new button */
.new-butn {
  height: 35px;
  background: #005724;
  border-radius: 5px;
}

/* new button */
.new-butn2 {
  /* height: 35px; */
  background: #005724;
  border-radius: 5px;
}

/* bar button */
.bar-butn {
  height: 35px;
  background: #f3f3f3;
  border-radius: 3.8536px;
}

/* search box */
.search-box-div {
  width: 300px;
  height: 35px;
  background: #ffffff;
  border: 1px solid gray;
  border-radius: 34px;
  display: flex;
}

/* search box */
.search-box-div2 {
  width: 300px;
  /* height: 35px; */
  background: #f3f3f3;
  border-radius: 34px;
  display: flex;
}

/* search icon */
.iconwrapperr {
  width: 50px;
  height: 30px;
  color: #005724;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
}

/* card css */
.table-cardstyle {
  box-sizing: border-box;
  position: absolute;
  width: 1200px;
  height: 548px;
  background: #ffffff;
  border-radius: 7.56957px;
}
