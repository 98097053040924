 @font-face {
   font-family: "SFProdisplay";
   src: local("SFProdisplay"),
     url(../styles/fonts/sf_pro_display.ttf) format("opentype");
 }

 @font-face {
   font-family: "Roboto";
   src: local("Roboto"),
     url(../styles/fonts/Roboto.ttf) format("opentype");
 }

 .orangebox {
   width: 4px;
   height: 24px;
   margin-top: 20px;
   display: "inline";
   background: #ff9a01;
   border-radius: 5px;
 }

 .cardtitle {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
   margin-top: 24px;
   margin-left: 15px;
   height: 19px;
 }

 .sub-head {
   width: 77px;
   height: 14px;
   font-family: 'SFProdisplay';
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 14px;
   color: #000000;
 }

 .box-area {
   background: #ffffff;
   border: 0.5px solid #bbbbbb;
   border-radius: 5px;
   width: 300px;
   height: 33px;
   padding-left: 10px;
 }

 .titlewrapper {
   display: flex;
   flex-direction: row;
 }

 /* button box */
 .configure-btn-section {
   display: flex;
   justify-content: flex-end;
   gap: 10px;
   margin-top: 10px;
   margin-bottom: -10px;
 }

 /* responder settings box */
 .responder-box {
   background: #FFFFFF;
   border: 0.5px solid #BBBBBB;
   border-radius: 5px;
   height: 35px;
   display: flex;
   justify-content: space-evenly;
 }

 /* common card component */
 .card-component {
   background: #F3F3F3;
   margin-bottom: 8px;
 }