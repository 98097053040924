.bar-container {
    display: flex;
    justify-content: space-evenly;
    margin-left: 40px;
}

.new-btn {
    height: 35px;
    background: #005724;
    border-radius: 5px;
}

.bar-btn {
    height: 35px;
    background: #F3F3F3;
    border-radius: 3.8536px;
}

.cardstyle {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 548px;
    background: #FFFFFF;
    border-radius: 7.56957px;
}

/* heading and page tip */
.box-container {
    display: "flex";
    justify-content: "space-between";
}

.page-tip {
    color: #62a8ea;
    height: 12px;
}

.page-tip:hover {
    color: #62a8ea;
    height: 12px;
}

::file-selector-button {
    display: none;
}