@font-face {
    font-family: "SFProdisplay";
    src: local("SFProdisplay"),
        url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

/* statement request label */
.statement_heading {
    width: 176px;
    height: 14px;
    font-family: 'SFProdisplay';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 14px;
    color: #000000;
    margin-left: 15px;
    margin-top: 10px;
}

/* search box */
.SearchBox {
    width: 300px;
    height: 40px;
    border-radius: 34px;
    margin-right: 15px;
    background: #F3F3F3;
}

/* search icon */
.iconwrapper {
    width: 50px;
    height: 40px;
    color: #005724;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* full search box */
.search_addbtn_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

/* upper box-label,search and add new button */
.statementtable-box {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px; */
    display: "flex";
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: "10px";
    align-items: center;
}

.outer_gray_box {
    width: 1009px auto;
    height: 548px auto;
    background: #F3F3F3;
    border-radius: 7.56957px;
    margin-top: 20px;
}

/* table */
.statementrequest-table {
    width: 977px auto;
    height: 441px auto;
    margin: 16px;
    border: 0.5px solid #BBBBBB;
    border-radius: 7.56957px;
}

.action_view{
    font-family: 'SFProdisplay';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    /* line-height: 11px; */
    /* text-align: center; */
    text-decoration: underline;
    color: #005724;
    cursor: "pointer";
}