@font-face {
    font-family: "fontsfree";
    src: local("fontsfree"),
        url(../styles/fonts/FontsFree-Net-SFProDisplay-Bold.ttf) format("opentype");
}

@font-face {
    font-family: "SFProdisplay";
    src: local("SFProdisplay"),
        url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

.popuptitle {
    font-family: 'fontsfree';
    font-weight: 700;
    font-size: 14px;
    color: #005724;
}

.popupsubtitle {
    font-family: 'fontsfree';
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    margin: 10px 0;
}

.underline {
    text-decoration: underline;
}

.comment {
    width: 100%;
    max-width: 460px;
}

.cancel {
    width: 100px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    background: #FFFFFF;
    color: #005724;
    border: 1px solid #FF9A01;
    box-sizing: "border-box";
    border-radius: 5px;
}

.tablerowlabel {
    width: 44px;
    font-family: 'SFProdisplay';
    font-style: normal;
    font-weight: 400;
    /* font-size: 10.9571px; */
    color: #000000;
}