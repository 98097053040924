@font-face {
    font-family: "fontsfree";
    src: local("fontsfree"),
        url("../styles/fonts/FontsFree-Net-SFProDisplay-Bold.ttf") format("opentype");
}

.menu-title {
    font-family: 'fontsfree';
    font-weight: 700;
    font-size: 16px;
    color: #005724;
}

.menulabel {
    font-family: 'fontsfree';
    font-weight: 700;
    font-size: "10px";
    color: #000000;

}



.sortlabel {
    height: 16px;
    background-color: '#ff0000';

}