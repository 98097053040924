@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url("./fonts/sf_pro_display.ttf") format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto.ttf") format("opentype");
}

/* search icon */
.iconstyle {
  width: 50px;
  height: 40px;
  color: #005724;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* search box */
.searchstyle {
  width: 300px;
  height: 30px;
  border-radius: 15px;
  margin-right: 15px;
  background: #f3f3f3;
}

/* orange color box */
.orangeBoxMatchedTable {
  /* width: 1270px; */
  background: #ff9a01;
  border-top-left-radius: 7.56957px;
  border-top-right-radius: 7.56957px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  /* border-radius: 7.56957px; */
  /* border-bottom-right-radius:"0px" */
}

/* matched Data label */
.matchedHead {
  width: 120px;
  height: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #005724;
  margin-left: 10px;
}

.matchedDatatable>td {
  min-width: 200px;
}

.matchedDataTableHead {
  overflow-x: scroll;
}

.popuplabels {
  font-family: "fontsfree";
  font-weight: 700;
  font-size: 14px;
  color: #005724;
}

.popupsublabels {
  font-family: "SFProdisplay";
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  /* margin: 10px 0; */
  text-align: "center";
}

.unmatchedSourceBox {
  box-sizing: border-box;
  /* border: 1px solid #dddddd; */
  border-radius: 7.56957px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.unmatchedSourceBoxLabels {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.unmatchedSourceBoxLabelsValues {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-top: -5px;
}

.unmatchedSumBox {
  width: 171px;
  background: #ffffff;
  border-radius: 7.56957px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
  padding: 10px;
}

.unmatchedSumBoxLabels {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.unmatchedSumBoxValues {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.selectedIemBox {
  width: 150px;
  height: 35px;
  background: #ffffff;
  border-radius: 34px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.popupmodalTitles {
  width: 162px;
  height: 16px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #005724;
}

.popupmodalSubtitles {
  width: 314px;
  height: 18px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

/* for matched and unmatched screens */
.managewidth {
  width: calc(98vw - 60px);
}

.redIconParentParty {
  position: relative;
}

.redCirleParty {
  height: 10px;
  width: 10px;
  background: #ff0000;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.viewremarkbtnParty {
  position: absolute;
  cursor: pointer;
  width: 0px;
  height: 0px;
  border-radius: 8px;
  background: #005724;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  top: -3px;
  left: -4.8px;
  z-index: 10;
  transition: 0.2s ease-in-out;
}

.viewremarkbtnParty span {
  font-family: "SFProdisplay";
  font-style: normal;
  font-size: 8px;
  color: transparent;

}

.redCirleblankParty {
  height: 8px;
  width: 8px;
  background: #ff0000;
  border-radius: 50%;
}

.redIconParentParty:hover .viewremarkbtnParty {
  width: 55px;
  height: 10px;
  padding: 6px;
}

.redIconParentParty:hover .viewremarkbtnParty span {
  color: #FFF;
}


.linknotebox {
  box-sizing: border-box;
  height: 24px;
  background: #fff1dc;
  border-radius: 5px;
  display: flex;
  padding: 5px;
}

.notetxt {
  height: 12px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  color: #000000;
  margin-left: 10px;
  margin-right: 15px;
}

.notemsg {
  height: 12px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #000000;
}

.blink {
  animation-name: blink;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}