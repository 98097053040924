 @font-face {
     font-family: "SFProdisplay";
     src: local("SFProdisplay"),
         url(../styles/fonts/sf_pro_display.ttf) format("opentype");
 }

 @font-face {
     font-family: "Roboto";
     src: local("Roboto"),
         url(../styles/fonts/Roboto.ttf) format("opentype");
 }

 .titlewrapper {
     display: flex;
     flex-direction: row;
 }

 .previewtext {
     margin-top: 1rem;
     color: #FFFFFF;
     width: 58px;
     height: 14px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 14px;
     margin-left: auto;
     margin-right: auto;
 }

 /* accordion main gray box */
 .accordion-box {
     /* margin: 10px 24px 16px 24px; */
     background: #F3F3F3;
     border-radius: 7px;
 }

 /* accordion heading and icon box */
 .accordion-header {
     display: "flex";
     justify-content: space-between;
 }

 /* orange line */
 .sideOrangeBox {
     width: 4px;
     height: 24px;
     background: #ff9a01;
     border-radius: 5px;
     margin-left: -8px;
 }

 /* card title */
 .sideTitle {
     position: absolute;
     width: 150px;
     height: 19px;
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 19px;
     color: #000000;
     margin-left: 15px;
     margin-top: -20px;
 }

 .table-title {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 700;
     color: #000000;
 }

 /* preview box headers */
 .headerstyle {
     width: 70vw;
     height: 49px;
     background: #FF9A01;
     display: flex;
     justify-content: space-between;
     margin-bottom: 20px;
     min-width: 750px;
 }

 /* below box details */
 .main-container {
     display: flex;
     background: #FFFFFF;
     border-radius: 10px;
 }

 .left-container {
     flex: 1 1 50%;
     min-height: 100%;
     max-width: 50%;
     padding: 0.5em;
     justify-content: space-between;
 }

 .right-container {
     flex: 0 1 50%;
     height: 100%;
     max-width: 50%;
 }

 /* upper box-parties selected label */
 .partyselect_label {
     width: 250px;
     height: 12px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     color: #000000;
     margin: 5px 0px 5px 16px;
 }

 /* selected parties number */
 .partySelect {
     width: 50px;
     height: 12px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 700;
     font-size: 12px;
     color: #000000;
     margin: 5px 0px 5px 10px;
 }

 /* upper box-file label */
 .file-label {
     width: 150px;
     height: 12px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     color: #000000;
     margin: 5px 0px 5px 16px;
 }

 /* upper box-file name */
 .filename {
     width: 270px;
     height: 12px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 700;
     font-size: 12px;
     color: #000000;
     margin: 5px 0px 0px 0px;
 }


 /* buttonbox */
 .buttonbox {
     display: flex;
     justify-content: flex-end;
     gap: 10px;
     margin: 0px 25px 10px 0px;
 }

 /* clear icon */
 .clearIcon {
     color: #005724;
     width: 60px;
     height: 14px;
     align-self: end;
     margin-bottom: 1rem;
     cursor: pointer;
 }

 /* request title name */
 .request-title-name {
     width: 400px;
     height: 10px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     color: #000000;
     margin-left: 150px;
     margin-top: 4px;
 }

 /* request div */
 .request-div {
     width: 763px;
     height: 80px auto;
     margin: 10px 24px 16px 24px;
     background: #F3F3F3;
     border-radius: 7.56957px;
     margin-left: 18px;
 }

 /* upper- two box style */
 .upper-box {
     background: #FFFFFF;
     border: 1px solid #F3F3F3;
     border-radius: 5px;
     height: 35px;
     padding: 3px 3px 3px 4px;
     display: flex;
     justify-content: center;
     
 }

 /* box style */
 .main-box {
     display: flex;
     margin-left: 18px;
     gap: 3px;
 }

 /* card-box */
 .card-box {
     width: 70vw;
     height: 400px auto;
     background: #FFFFFF;
     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
     border-radius: 10px;
     margin-left: 200px;
     margin-top: 10px;
 }

 /* backend data */
 .backend-data {
     display: flex;
     justify-content: flex-end;
     align-items: center;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 19px;
     color: #000000;
 }

 .backend-data-div {
     display: flex;
     flex-direction: column;
     justify-content: right;
     align-items: right;
 }

 .label-container {
     width: 100%;
     display: flex;
     gap: 50px;
 }

 .new-container {
     width: 100%;
 }

 /* div for label and backend data  */
 .label-div {
     display: inline;
     justify-content: space-between;
 }