@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../styles/fonts/Roboto.ttf) format("opentype");
}

.main_heading {
  width: 132px;
  height: 14px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.right_side_display {
  /* width: 33%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 20px;
  padding-block: 30px;
  background: #f3f3f3;
  border-radius: 7px;
}

.left_side_display {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px;
}

.addparty {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #005724;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  text-decoration: underline;
}

/* button box */
.below-btn-section {
  gap: 20px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* legder and open item button */
.toggle_btn {
  box-sizing: border-box;
  width: 300px;
  height: 33px;
  border: 0.5px solid #bbbbbb;
  border-radius: 5px;
}

/* name and icon div-right side */
.upper_head {
  /* width: 75%; */
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 15px; */
  /* ;
    margin-left: 60px;
    margin-top: -30px; */
  position: relative;
}

/* setting- heading */
.setting_head {
  display: flex;
  width: 60px;
  height: 19px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

/* 
.display_msg_box {
    display: flex;
    position:static;
} */

/* info i icon */
.infoicondata {
  display: flex;
  width: 200px;
  height: 80px;
  background: linear-gradient(
      0deg,
      rgba(255, 154, 1, 0.24),
      rgba(255, 154, 1, 0.24)
    ),
    #ffffff;
  border-radius: 5px;
  padding: 10px;
  /* top: 0;right: 0;
        position: absolute; */
}

/* Information message box on icon click */
.InfoMessage {
  width: 170px;
  height: 65px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 156.69%;
  color: #005724;
}

/* labels style */
.sub-head-party {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

/* action- Menu Items of bar button */
.action_heading {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 44px;
  color: #005724;
}

.card_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

/* names- Sub Menu Items of bar button */
.sub_menu_headings {
  display: flex;
  gap: 10px;
  padding-right: 50px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #000000;
}

/* icons- Sub Menu Items of bar button */
.sub_menu_icons {
  height: 30px;
  width: 20px;
  border-radius: 0px;
  margin-left: 10px;
  color: #005724;
}

.arrow-back-icon {
  width: 16px;
  height: 16px;
  color: #005724;
  cursor: pointer;
}

/* .clear-icon {
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    cursor: pointer;
    color: #005724;
} */

.bar-icon {
  width: 24px;
  height: 24px;
  background: #ececec;
  border-radius: 5px;
  cursor: pointer;
}

.close-icon {
  top: 0;
  right: 0;
  margin-top: 14px;
  margin-right: 16px;
  margin-bottom: 50px;
  position: absolute;

  /* position: "absolute";
    left: 69.45%;
    right: 29.69%;
    top: 31.67%;
    bottom: 66.8%;
    background: " #005724"; */
}

.Ibtn {
  margin-top: 10px;
  /* position: "absolute";
    left: 49.84%;
    right: 47.73%;
    top: 34.3%;
    bottom: 61.41%;
    background: "#005724"; */
}

.setting_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.clearLabel {
  /* position: absolute;
    width: 146px;
    height: 14px;
    left: 575px;
    top: 311px; */
  font-family: "SFProdisplay";
  height: 14px;
  width: 146px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  color: #000000;
}

.msgLabel {
  /* position: absolute; */
  width: 418px;
  height: 60px;
  /* left: 439px;
    top: 333px; */
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

/* Information message box on icon click */
.NewInfoMessage {
  /* width: 170px; */
  /* height: 80px; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 156.69%;
  color: #005724;
}

.main_boxxxx {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 20px;
  padding-block: 30px;
  border-radius: 7px;
}

.selectedbtn {
  width: 60px;
  height: 31px;
  border-radius: 46px;
  border: 1px solid #bdbdbd;
  background: #d2ffe5;
  color: #005724;
}

.btn {
  width: 60px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #bdbdbd;
  color: #005724;
}
