@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../styles/fonts/Roboto.ttf) format("opentype");
}

body {
  padding: 0;
  margin: 0;
  font-family: "SFProdisplay";
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar:hover{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ul-list {
  font-family: "SFProdisplay";
  margin-left: 20px;
}

.data-table {
  font-family: "SFProdisplay";
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.table-head {
  font-weight: normal;
}

.row-data {
  font-weight: bold;
}

.table-head,
.row-data {
  height: 40px;
  padding-inline: 10px;
}

.table-head:first-child,
.row-data:first-child {
  text-align: left;
}

.table-head:last-child,
.row-data:last-child {
  text-align: right;
}

.table-row:last-child .row-data:first-child {
  border: 1px solid #eeeeee;
  border-top: none;
  border-right: none;
  border-bottom-left-radius: 10px;
}

.table-row:last-child .row-data:last-child {
  border: 1px solid #eeeeee;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 10px;
}

.table-head:first-child {
  border: 1px solid #eeeeee;
  border-right: none;
  border-top-left-radius: 7px;
  background-color: #ffffff;
}

.table-head:last-child {
  border: 1px solid #eeeeee;
  border-left: none;
  border-top-right-radius: 7px;
  background-color: #ffffff;
}

.row-data:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #f9f9f9;
}

.row-data:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #f9f9f9;
}

.row-data:first-child {
  border-left: 1px solid #eeeeee;
}

.row-data:last-child {
  border-right: 1px solid #eeeeee;
}
