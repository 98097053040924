@font-face {
    font-family: "SFProdisplay";
    src: local("SFProdisplay"),
        url(../styles/fonts/sf_pro_display.ttf) format("opentype");
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
        url(../styles/fonts/Roboto.ttf) format("opentype");
}

.stoptitle {
    font-family: 'SFProdisplay';
    font-weight: 700;
    font-size: 18px;
    color: #005724;
    margin-left: 250px;
}

.stopsubtitle {
    margin: 20px 0; 
    font-family: 'SFProdisplay';
    font-style: normal;
    font-weight: 700;
    font-size: "10px";
    color: #000000;
    margin-bottom: 20px;
    
}

.stopmaincontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.stopcancel {
    width: 100px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    background: #FFFFFF;
    color: #005724;
    border: 1px solid #FF9A01;
    box-sizing: "border-box";
    border-radius: 5px;
    text-align:"center";
  }

  .clearIcon{
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    position: absolute;
  }

  .btn-confirm {
    width: 123px;
    height: 35px;
    background: #005724;
    border-radius: 5px;
  }
 
  .btn-cancel {
    width: 123px;
    height: 35px;
    background: #FFFFFF;
    color: #005724;
    border: 1px solid #FF9A01;
    box-sizing: "border-box";
    border-radius: 5px;
  }
 
  /* .btn-browse:hover {
    background: #005724;
  }
 
  .btn-next:hover {
    background: #005724;
  }
 
  .btn-back:hover {
    background: #FFFFFF;
    color: #005724;
    border: 1px solid #FF9A01;
  }
  */