@font-face {
  font-family: "SFProdisplay";
  src: local("SFProdisplay"),
    url("./assets/styles/fonts/sf_pro_display.ttf") format("opentype");
}

@font-face {
  font-family: "ekmukta";
  src: local("Mukta"),
    url("./assets/styles/fonts/Mukta-Light.ttf") format("opentype");
}

@font-face {
  font-family: "ekmukta-bold";
  src: local("Mukta"),
    url("./assets/styles/fonts/EkMukta-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./assets/styles/fonts/Roboto.ttf") format("opentype");
}

@font-face {
  font-family: "fontsfree";
  src: local("fontsfree"),
    url("./assets/styles/fonts/FontsFree-Net-SFProDisplay-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "signature";
  src: local("Qwitcher Grypen"),
    url("./assets/styles/fonts/QwitcherGrypen-Regular.ttf") format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@400;700&family=Square+Peg&display=swap');

::-webkit-scrollbar {
  width: 8px; /* Default width */
  height: 8px;
}

/* Target the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #D6D6D6; /* Scrollbar thumb color */
  border-radius: 8px;
}



.boGEYA {
  color: #ff0000;
}

/* Helpertext color */
.jnNruL {
  color: #ff0000;
}

/* mandatory field * color  */
.text-danger {
  color: #ff0000;
}

/* for labels */
.text-font {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

/* for add party label */
.heading {
  width: "94px";
  height: "14px";
  font-family: "SFProdisplay";
  font-style: "normal";
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: "#212121";
  margin-left: 10px;
}

.box {
  width: 300px;
  height: 33px;
  border: 0.5px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 5px;
}

/* Tabs */
.tab-menu {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  width: 140px;
}

/* add row button of contact person and erp table */
#addRow {
  height: 12px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12.6812px;
  text-align: center;
  color: #005724;
  text-transform: capitalize;
}

#deletebtn {
  width: 50px;
  height: 200px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 700;
  font-size: 12.6812px;
  color: #EE2828;
  text-align: center;
}

/* text allowed upto 10 */
#txt {
  width: 99px;
  height: 12px;
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12.6812px;
  line-height: 11px;
  text-align: center;
  color: #484848;
}

.MuiTableRow-root-85 {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}


.MuiTableHead-root-58 ContactPerson-tableHead-2 {
  font-family: "SFProdisplay";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.MuiInputBase-root-jyJNmy MuiOutlinedInput-root-hdWbLv gPdlvf jVkMII MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall {
  color: #005724;
}
/* 
.ezIlWQ.Mui-selected {
  background-color: #deffeb;
}

.ezIlWQ.Mui-selected:hover {
  background-color: #deffeb;
} */

.MuiTab-root.Mui-selected {
  background: "#005724";
  color: "#ffffff";
  border-radius: "50px";
  width: "171px";
  height: "5px";
}

/* reusable code */
.page-tip {
  color: #62a8ea;
  height: 12px;
}

* {
  margin: 0;
  padding: 0;
}



/* #pdf {
  padding: 4px;
  width: 2400px;
  height: 3400;
} */


.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #005724;
  border-radius: 70%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
  margin-top: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

.zoom-ninety{
  @media screen and (max-width: 1300px) {
      zoom: 80%;
      }
      @media screen and (max-width: 900px) {
    zoom: 65%;
      }
}
