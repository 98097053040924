.infobox .box {
    height: 92px;
    width: min-content;
    padding: 8px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 15px 0px;
    border: none;
    position: absolute;
    z-index: 10;
    display: none;
    cursor: pointer;
}

.title {
    cursor: pointer;
    color: #000;
    font-size: 12px;
    text-decoration: underline;
    font-family: 'SFProdisplay';
    font-weight: 400;
}

.infobox:hover .box{
    display: block;
    margin-top: 20px;
}
