 @font-face {
     font-family: "SFProdisplay";
     src: local("SFProdisplay"),
         url(../styles/fonts/sf_pro_display.ttf) format("opentype");
 }

 /* main gray color box */
 .outerbox {
     width: 1009px auto;
     height: 548px auto;
     background: #F3F3F3;
     border-radius: 7.56957px;
 }

 /* table */
 .table-container {
     width: 977px auto;
     height: 441px auto;
     margin: 16px;
     border: 0.5px solid #BBBBBB;
     border-radius: 7.56957px;
 }

 /* row select box */
 .selected-box {
     display: flex;
     justify-content: space-between;
     width: 150px;
     height: 40px;
     background: #FFFFFF;
     border-radius: 34px;
     margin-left: 10px;
 }

 /* selected name label */
 .select-name {
     width: 57px;
     height: 12px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 11px;
     color: #005724;
     padding: 15px;
 }

 /* selected number value */
 .selected-num {
     width: 24px;
     height: 12px;
     font-family: 'SFProdisplay';
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 11px;
     color: #FF9A01;
     padding: 15px;
     margin-right: 20px;
 }

 /* search box */
 .search-box {
     width: 300px;
     height: 40px;
     background: #FFFFFF;
     border-radius: 34px;
     margin-right: 15px;
 }

 /* search icon */
 .iconwrapper {
     width: 50px;
     height: 40px;
     color: #005724;
     position: absolute;
     pointer-events: none;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 /* below row per page */
 .page-per-row {
     background: #FFFFFF;
     border: 0.827206px solid #DFE3E8;
     border-radius: 4px;
     width: 125px;
 }

 /* pagination css */
 .pagination-box {
     /* margin-right: 10px; */
     color: #005724;
 }

 .pagination-box .Mui-selected {
     color: #ffffff;
     background-color: #005724;
 }
 
 .pagination-box .MuiPaginationItem-previousNext:hover,
 .MuiPaginationItem-page:hover {
     color: #ffffff;
     background-color: #005724;
 }

 /* buttons */
 .button-box {
     display: flex;
     justify-content: flex-end;
     gap: 10px;
     margin-top: 15px;
     margin-bottom: -20px;
 }