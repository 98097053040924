
.table1-style{
    width: 100%;
    font-family: 'ekmukta-bold'!important;
    border-radius: 7.5px;
    overflow: hidden;
    border: 1px solid #005724;
    border-spacing: 0 0;
}


.table3-style{
    width: 100%;
    font-family: 'ekmukta';
    border-radius: 7.5px;
    overflow: hidden;
    border: 1px solid #005724;
    border-spacing: 0 0;
}

.table1-style > thead > tr, .table3-style > thead > tr {
    background-color: #005724;
    height: 44px;
    color: #000;
    font-family: inherit;
    

}

.table1-style > thead > tr > th,.table3-style > thead > tr > th{
    text-align: center;
    background: #005724;
    border-bottom: 1px solid #005724;
    color: #FFF;
}

.table1-style > tbody, .table3-style > tbody {
    outline: 0.5px solid #BBBBBB;
    outline-offset: -0.5px;
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
}

.table1-style > tbody > tr > td,.table3-style > tbody > tr > td {
    border-bottom: 0.5px solid #BBBBBB;
}

.table1-style > tbody > tr > td ,.table3-style > tbody > tr > td {
 text-align: center;
 height: 34px;
}

.table1-style > tbody > tr > td:first-of-type {
    text-align: center;
}

.table1-style > thead > tr > th {
text-align: left;
} 

/* .table-style tr:last-child th:first-child {
    border: 2px solid orange;
    border-top-left-radius: 10px;
}
    
.table-style tr:last-child th:last-child {
    border: 2px solid green;
    border-top-right-radius: 10px;
} */