.contact_card{
    height: 200px auto;
    margin-top: 20px;
    border: 0.5px solid #BBBBBB;
    box-sizing:border-box;
    border-radius:7.56957px;
    margin-bottom: 20px;
}
.addbtn{
    margin:10px;
    margin-bottom: 20px;
}

.datagridtable{
    height: 300px;
    margin: 20px;
}

.cell-error{
    border: 2px solid red;
}