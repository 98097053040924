@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url("../styles/fonts/Inter-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "SFProdisplay";
    src: local("SFProdisplay"),
        url("../styles/fonts/sf_pro_display.ttf") format("opentype");
}

.main-modal {
    width: 619px;
    height: 291px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-title {
    width: 185px;
    height: 18px;
    left: 635px;
    top: 499px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    margin-left: 250px;
}

.verifylabel {
    width: 157px;
    height: 15px;
    left: 649px;
    top: 611px;
    font-family: "Inter";
    font-style: "normal";
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-left: 250px;
}

.sub-title {
    width: 414px;
    height: 50px;
    left: 650px;
    top: 542px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    margin: 20px 0px 0px 110px;
}

.gifimg {
    width: 139px;
    height: 139px;
    left: 658px;
    top: 449px;
    margin-left: 250px;
}

.btn {
    width: 123px;
    height: 35px;
    background: #005724;
    color: #FFFFFF;
    border-radius: 5px;
    margin-left: 250px;
    margin-top: 30px;
    font-family: "SFProdisplay";
}

.btn:hover {
    width: 123px;
    height: 35px;
    background: #005724;
    color: #FFFFFF;
    border-radius: 5px;
    margin-left: 250px;
    margin-top: 30px;
    font-family: "SFProdisplay";
}