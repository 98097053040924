@font-face {
    font-family: "SFProdisplay";
    src: local("SFProdisplay"),
        url("./fonts/sf_pro_display.ttf") format("opentype");
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
        url("./fonts/Roboto.ttf") format("opentype");
}


/* import data table box texts */
.text1 {
    height: 12px;
    font-family: "SFProdisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 12.6812px;
    color: #000000;
    margin-top: 10px;
}

.text-Items {
    display: flex;
    gap: 5px;
}

.text2 {
    height: 12px;
    font-family: "SFProdisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 12.6812px;
    color: #ff0000;
    margin-top: 10px;
}

.text3 {
    height: 12px;
    font-family: "SFProdisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 12.6812px;
    color: #008000;
    margin-top: 10px;
}

/* upper blue box */
.upper-import-box {
    width: 100%;
    padding: 10px;
    border: 2px solid #75C5ED;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* number of items-failed */
.number-items {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
}

/* buttons  */
.btn-box {
    display: flex;
    justify-content: space-around;
    gap: 5px;
}

/* table */
.maintable-container {
    width: 977px auto;
    height: 441px auto;
    margin-top: 16px;
    border: 0.5px solid #BBBBBB;
}

/* below row per page */
.page-perrow {
    background: #FFFFFF;
    border: 0.827206px solid #DFE3E8;
    border-radius: 4px;
    width: 125px;
    margin-left: 10px;
}

/* pagination css */
.paginationbox {
    margin-right: 10px;
    color: #005724;
}

/* table Error Details column */
.error-details {
    height: 12px;
    font-family: "SFProdisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 12.6812px;
    color: #ff0000;
    margin-top: 10px;
}